import styled from 'styled-components';

export const FeatureSection = styled.div`
  position: relative;
`;

export const FeatureImg = styled.img`
  width: 100%;
  height: 14rem;
  object-fit: cover;
  @media (min-width: 1200px) {
    height: 43.7rem;
  }
`;

export const FeatureText = styled.div`
  align-items: center;
  display: flex;
  color: ${props => props.theme.white};
  font-family: 'Heuristica';
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 4.4rem;
  margin: 0;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 3.4rem;
    line-height: 4.4rem;
  }
`;

export const Subtitle = styled.h2`
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.2rem;
  color: ${props => props.theme.subtitleColor};
  letter-spacing: 0.16rem;
  line-height: 2.8rem;
  text-align: left;
  margin: ${props => props.margin || '2rem 0 1.4rem'};
  text-transform: uppercase;
  @media (min-width: 1200px) {
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-family: 'Heuristica';
  font-weight: 600;
  font-size: 2.4rem;
  color: ${props => props.theme.txtColor};
  letter-spacing: 0;
  line-height: 2.8rem;
  margin: 0;
  @media (min-width: 1200px) {
    text-align: center;
    margin-bottom: 1.4rem;
  }
`;

export const Text = styled.p`
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.5rem;
  color: ${props => props.theme.paragraphColor};
  letter-spacing: 0;
  text-align: ${props => props.align || 'left'};
  line-height: 2.4rem;
  @media (min-width: 1200px) {
    margin: ${props => props.deskMargin || '0 15rem'};
    text-align: center;
  }
`;
