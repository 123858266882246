import React from 'react';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import Resources from '@components/pages/resources/index';

const ResourcesPage = props => {
  return (
    <Layout {...props}>
      <SEO title="Resources" />
      <Resources />
    </Layout>
  );
};

export default ResourcesPage;
