import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Container, Section } from '@components/styledComponents/index';
import Slider, {
  Slide,
  SlideImage,
  SlideTitle,
  SlideText,
  SlideText2,
  SlideMore,
  SlideMoreAnchor,
} from '@components/slider/index';
import {
  FeatureImg,
  FeatureSection,
  FeatureText,
  Subtitle,
  Text,
  Title,
} from './styles';

const Resources = () => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <FeatureSection>
        <FeatureImg
          alt="Resources page"
          src={require('@images/resources-bg.jpg')}
          srcSet={`${require('@images/resources-bg.jpg')}, ${require('@images/resources-bg@2x.jpg')} 2x`}
        />
        <FeatureText>The CIO-on-tap Advantage</FeatureText>
      </FeatureSection>
      <Container deskDir={'column'}>
        <Section padding={'0 1.4rem'}>
          <Subtitle>Resources</Subtitle>
          <Title>The CIO-on-tap Advantage</Title>
          <Text>
            Wareef is an IT provider with a unique service offering that’s
            dedicated exclusively to the GCC market. Take a closer look into how
            its IT solutions help clients throughout the region, in a wide
            variety of verticals, meet their business and technology goals with
            these resources.
          </Text>
        </Section>
        <Section>
          <Slider>
            <Slide>
              <SlideImage
                src={require('@images/cheif-information.jpg')}
                srcSet={`${require('@images/cheif-information.jpg')}, ${require('@images/cheif-information@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>Whitepaper: The CIO-on-tap model</SlideTitle>
                <SlideText>
                  The CIO-on-tap model offers a unique value proposition in the
                  GCC marketplace to organizations of all sizes, across vertical
                  markets. This paper outlines an approach built on tailored,
                  modular solutions that can be customized to fit a variety of
                  customers’ IT needs, budgets and business strategies,
                  providing a complete end-to-end transformation for any
                  IT-related project.
                </SlideText>
                <SlideMoreAnchor
                  target="_blank"
                  href={`${process.env.BACKEND_URL}wp-content/uploads//2015/01/The_CIO-On-Tap_Advantage.pdf`}
                >
                  Download Now
                </SlideMoreAnchor>
              </div>
            </Slide>
            <Slide>
              <SlideImage
                src={require('@images/role-of-it.jpg')}
                srcSet={`${require('@images/role-of-it.jpg')}, ${require('@images/role-of-it@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>
                  The Role of IT in Enabling the Transformation of Saudi Arabia
                </SlideTitle>
                <SlideText>
                  Economic Diversification” has been Saudi Arabia’s Achilles
                  heel for a few decades now. Although, Saudi Arabia’s oil
                  wealth has allowed it to undergo unprecedented modernization,
                  including widespread
                </SlideText>
                <SlideMoreAnchor
                  target="_blank"
                  href={`${process.env.BACKEND_URL}wp-content/uploads/2016/07/Whitepapaer-Role-of-IT-in-Enabling-theTransformation-of-Saudi-Arabia.pdf`}
                >
                  Download Now
                </SlideMoreAnchor>
              </div>
            </Slide>
            <Slide>
              <SlideImage
                src={require('@images/open-source-test.jpg')}
                srcSet={`${require('@images/open-source-test.jpg')}, ${require('@images/open-source-test@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>Open Source Testing in Saudi Arabia</SlideTitle>
                <SlideText>
                  Test Management tools are essential to any test team. These
                  tools are used to acquire requirements, design test cases, map
                  the test cases to the requirements, automate the test, test
                  execution, reporting and many more. The main purpose of these
                  tools are improving productivity and providing more
                  visibility.
                </SlideText>
                <SlideMoreAnchor
                  target="_blank"
                  href={`${process.env.BACKEND_URL}wp-content/uploads/2016/12/Whitepaper-Open_Source_Testing_in_Saudi_Arabia.pdf`}
                >
                  Download Now
                </SlideMoreAnchor>
              </div>
            </Slide>
          </Slider>
        </Section>
      </Container>
      <Container bgColor={theme.secondaryBgColor}>
        <Section padding={'0 1.4rem'}>
          <Subtitle margin={'2rem 0 0'}>INFOGRAPHICS</Subtitle>
          <Text deskMargin={'2.1rem 15rem 5.3rem'}>
            From a deep knowledge of the GCC marketplace to our unique IT
            services that meet the needs of a wide range of customers, Wareef
            has a great deal to offer. And, consequently, a great deal of
            information to share. For a glimpse of what Wareef can do in the
            GCC, we offer this series of business-related infographics.
          </Text>
          <Slider deskItemWidth={'26.3rem'}>
            <Slide>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.BACKEND_URL}wp-content/uploads/2015/10/Wareef-Banking-Service-Offering-Infographic.jpg`}
              >
                <SlideImage
                  height={'23.6rem'}
                  src={require('@images/infographic/architecting-tomorrow-banking.jpg')}
                  srcSet={`${require('@images/infographic/architecting-tomorrow-banking.jpg')}, ${require('@images/infographic/architecting-tomorrow-banking@2x.jpg')} 2x`}
                />
              </a>
              <div>
                <SlideTitle>
                  Architecting Tomorrow’s Banking Experience
                </SlideTitle>
                <SlideText2>
                  Examine how more than 15 years of experience in the GCC
                  banking sector, particularly in Saudi.
                </SlideText2>
              </div>
            </Slide>
            <Slide>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.BACKEND_URL}wp-content/uploads/2015/10/Information-Security-on-Saudi-e-Gov-Infographic.jpg`}
              >
                <SlideImage
                  height={'23.6rem'}
                  src={require('@images/infographic/government-cyber-attacks.jpg')}
                  srcSet={`${require('@images/infographic/government-cyber-attacks.jpg')}, ${require('@images/infographic/government-cyber-attacks@2x.jpg')} 2x`}
                />
              </a>
              <div>
                <SlideTitle>
                  Government Cyber Attacks: The New Political Battleground
                </SlideTitle>
                <SlideText2>
                  Governments are facing one of the biggest threats of all,
                  cyber crime and attacks are forming a new medium of
                  information war.
                </SlideText2>
              </div>
            </Slide>
            <Slide>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.BACKEND_URL}wp-content/uploads/2015/05/erp_hiresx70.jpg`}
              >
                <SlideImage
                  height={'23.6rem'}
                  src={require('@images/infographic/why-your-business-eeds.jpg')}
                  srcSet={`${require('@images/infographic/why-your-business-eeds.jpg')}, ${require('@images/infographic/why-your-business-eeds@2x.jpg')} 2x`}
                />
              </a>
              <div>
                <SlideTitle>
                  Why Your Business Needs a Single Giant Brain
                </SlideTitle>
                <SlideText2>
                  Any growing business will face several challenges and has to
                  overcome many obstacles to keep up with its operations.
                </SlideText2>
              </div>
            </Slide>
            <Slide>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.BACKEND_URL}wp-content/uploads/2015/05/tmsweb-01x702.jpg`}
              >
                <SlideImage
                  height={'23.6rem'}
                  src={require('@images/infographic/transport-management-system.jpg')}
                  srcSet={`${require('@images/infographic/transport-management-system.jpg')}, ${require('@images/infographic/transport-management-system@2x.jpg')} 2x`}
                />
              </a>
              <div>
                <SlideTitle>
                  Transport Management System: A New Route In Saudi
                </SlideTitle>
                <SlideText2>
                  Take your transport operations to the next level and gain a
                  competitive advantage in the Saudi transport market
                </SlideText2>
              </div>
            </Slide>
            <Slide>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.BACKEND_URL}wp-content/uploads/2015/01/Wareef_ScalableSolutions.jpg`}
              >
                <SlideImage
                  height={'23.6rem'}
                  src={require('@images/infographic/wareef-scalable-solutions.jpg')}
                  srcSet={`${require('@images/infographic/wareef-scalable-solutions.jpg')}, ${require('@images/infographic/wareef-scalable-solutions@2x.jpg')} 2x`}
                />
              </a>
              <div>
                <SlideTitle>Wareef’s scalable solutions</SlideTitle>
                <SlideText2>
                  We provide a unique offering that’s customized for the GCC
                  market, where firms typically encounter obstacles such as
                  inflexible structure, poor delivery, poor governance and a
                  lack of quality assurance.
                </SlideText2>
              </div>
            </Slide>
            <Slide>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.BACKEND_URL}wp-content/uploads/2015/01/Wareef_Cio_On_Tap.jpg`}
              >
                <SlideImage
                  height={'23.6rem'}
                  src={require('@images/infographic/cio-on-tap-approach.jpg')}
                  srcSet={`${require('@images/infographic/cio-on-tap-approach.jpg')}, ${require('@images/infographic/cio-on-tap-approach@2x.jpg')} 2x`}
                />
              </a>
              <div>
                <SlideTitle>CIO-on-tap Approach</SlideTitle>
                <SlideText2>
                  We tailor customized solutions for clients through our
                  advisory and execution services, budgeting to couple with the
                  best-in-breed solution value and the alignment of business and
                  IT strategies.
                </SlideText2>
              </div>
            </Slide>
            <Slide>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.BACKEND_URL}wp-content/uploads/2015/01/Wareef_Cio_On_Tap.jpg`}
              >
                <SlideImage
                  height={'23.6rem'}
                  src={require('@images/infographic/it-outsourcing-untold-truth.jpg')}
                  srcSet={`${require('@images/infographic/it-outsourcing-untold-truth.jpg')}, ${require('@images/infographic/it-outsourcing-untold-truth@2x.jpg')} 2x`}
                />
              </a>
              <div>
                <SlideTitle>IT Outsourcing: Untold truth</SlideTitle>
                <SlideText2>
                  Outsourcing is no longer a cost-cutting tactic that simply
                  supports business. On the contrary, outsourcing can reduce
                  both cost and overload, improve processes and quality and help
                  accelerates business growth.
                </SlideText2>
              </div>
            </Slide>
            <Slide>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.BACKEND_URL}wp-content/uploads/2015/01/Wareef_Pillar-of-the-GCC.jpg`}
              >
                <SlideImage
                  height={'23.6rem'}
                  src={require('@images/infographic/wareef-pillar-of-the-gcc.jpg')}
                  srcSet={`${require('@images/infographic/wareef-pillar-of-the-gcc.jpg')}, ${require('@images/infographic/wareef-pillar-of-the-gcc@2x.jpg')} 2x`}
                />
              </a>
              <div>
                <SlideTitle>Wareef: A pillar of The GCC</SlideTitle>
                <SlideText2>
                  One our primary strengths lies in taking an Arabic approach to
                  all IT solutions in the GCC’s main strategic vertical markets,
                  such as banking, construction, manufacturing, telecom and
                  insurance.
                </SlideText2>
              </div>
            </Slide>
          </Slider>
        </Section>
      </Container>
      <Container deskDir={'column'}>
        <Section>
          <Subtitle margin={'0 0 2.9rem 0'}>Articles</Subtitle>
          <Slider>
            <Slide>
              <SlideImage
                src={require('@images/articles/failure-and-beyond.jpg')}
                srcSet={`${require('@images/articles/failure-and-beyond.jpg')}, ${require('@images/articles/failure-and-beyond@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>
                  Failure and Beyond: How Software Quality Might Cost You Your
                  Life!
                </SlideTitle>
                <SlideText>
                  In this rapidly changing world where software has become an
                  integrated necessity in most of life’s aspects, one misstep
                  can be costly. In some cases, it could be fatal to your
                  business and your life.
                </SlideText>
                <SlideMore to={'/articles/failure-and-beyond'}>Read More</SlideMore>
              </div>
            </Slide>
            <Slide>
              <SlideImage
                src={require('@images/articles/test-management-office.jpg')}
                srcSet={`${require('@images/articles/test-management-office.jpg')}, ${require('@images/articles/test-management-office@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>Test Management Office “TMO”</SlideTitle>
                <SlideText>
                  A high quality product that meets customer needs is the
                  ultimate common goal that all testing teams thrive to achieve.
                  Testing activities and processes should be well planned,
                  prepared, executed and effectively tracked and monitored to
                  ensure meeting that goal, and test management…
                </SlideText>
                <SlideMore to={'/articles/tmo'}>Read More</SlideMore>
              </div>
            </Slide>
          </Slider>
        </Section>
      </Container>
      <Container deskDir={'column'} bgColor={theme.secondaryBgColor}>
        <Section>
          <Subtitle margin={'0 0 2.9rem 0'}>Blogs</Subtitle>
          <Slider>
            <Slide>
              <SlideImage
                src={require('@images/blogs/7skillstechniquesba.jpg')}
                srcSet={`${require('@images/blogs/7skillstechniquesba.jpg')}, ${require('@images/blogs/7skillstechniquesba@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>
                  The 7 Skills and Techniques of a Successful Business Analyst
                </SlideTitle>
                <SlideText>
                  In this rapidly changing world where software has become an
                  integrated necessity in most of life’s aspects, one misstep
                  can be costly. In some cases, it could be fatal to your
                  business and your life.
                </SlideText>
                <SlideMore to={'/blogs/the-7-skills-techniques'}>Read More</SlideMore>
              </div>
            </Slide>
            <Slide>
              <SlideImage
                src={require('@images/blogs/whentostoptesting.jpg')}
                srcSet={`${require('@images/blogs/whentostoptesting.jpg')}, ${require('@images/blogs/whentostoptesting@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>When is the Best Time to Stop Testing?</SlideTitle>
                <SlideText>
                  A high quality product that meets customer needs is the
                  ultimate common goal that all testing teams thrive to achieve.
                  Testing activities and processes should be well planned,
                  prepared, executed and effectively tracked and monitored to
                  ensure meeting that goal, and test management…
                </SlideText>
                <SlideMore to={'/blogs/when-to-stop-testing'}>Read More</SlideMore>
              </div>
            </Slide>
          </Slider>
        </Section>
      </Container>
    </>
  );
};

export default Resources;
