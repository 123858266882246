import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const Container = styled.div`
  .carousel-slider {
    padding-bottom: 5rem;
    .control-dots {
      .dot {
        box-shadow: none;
        width: 1rem;
        height: 1rem;
        background-color: ${props => props.theme.quoteTxtColor};
        opacity: 0.2;
        transition: opacity 0.5s linear;
        &.selected {
          opacity: 1;
        }
      }
    }
  }
  .carousel {
    .slide {
      background-color: ${props => props.theme.white};
    }
  } 
`;

export const Slide = styled.div`
  width: 100%;
  background: ${props => props.theme.bgColor};
  background-size: 27rem 21.8rem;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  > div {
    padding: 1.8rem 1.4rem 2.4rem;
  }

  @media (min-width: 1200px) {
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.05);
    min-height: 50.7rem;
    width: 36rem;
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Image = styled.img`
  object-fit: cover;
  height: ${props => props.height || '18rem'};
  width: 100%;
`;

export const Title = styled.h2`
  font-family: 'Heuristica';
  font-weight: 700;
  font-size: 2.4rem;
  color: ${props => props.theme.txtColor};
  letter-spacing: 0;
  line-height: 3.6rem;
  margin: 0 0 1rem 0;
`;

export const Text = styled.p`
  font-family: 'Avenir Book';
  font-size: 1.4rem;
  color: ${props => props.theme.paragraphColor};
  letter-spacing: 0;
  line-height: 2.4rem;
  margin: 0 0 3rem 0;
`;

export const Text2 = styled.p`
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.8rem;
  color: ${props => props.theme.paragraphColor};
  letter-spacing: 0;
  line-height: 3.2rem;
  margin: 1rem 0;
`;

export const More = styled(Link)`
  color: ${props => props.theme.moreLink2};
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.6rem;
  text-decoration: none;
  position: absolute;
  bottom: 2.1rem;
`;

export const MoreAnchor = styled.a`
  color: ${props => props.theme.moreLink2};
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.6rem;
  text-decoration: none;
  position: absolute;
  bottom: 2.1rem;
`;

export const ListingBox = styled.div`
  margin: 0 15rem;
  display: grid;
  ${props => props.deskItemWidth ? css`
    justify-content: center;
    grid-template-columns: 26.3rem 26.3rem 26.3rem 26.3rem;
    grid-gap: 1rem;
    ${Slide} {
      width: 26.3rem;
    }
    ${Image} {
      width: 26.3rem;
    }
    ${Title} {
      font-family: 'Heuristica';
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
    ${Text} {
      font-family: 'Avenir';
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  ` : css`
    grid-template-columns: 36rem 36rem 36rem;
  `};
  justify-content: center;
  grid-gap: 3rem;
`;
