import AppContext from '@utils/app-context';
import { desktopBaseWidth } from '@utils/consts';
import { isBrowser } from '@utils/helpers';
import React, { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  Container,
  Slide,
  Image,
  Text,
  Text2,
  Title,
  More,
  ListingBox,
  MoreAnchor,
} from './styles';

const CarouselSlider = props => (
  <Carousel
    showStatus={false}
    infiniteLoop={true}
    showArrows={false}
    interval={5000}
    autoPlay={true}
    showThumbs={false}
  >
    {props.slides}
  </Carousel>
);

const ListingSlider = props => (
  <ListingBox deskItemWidth={props.deskItemWidth}>{props.slides}</ListingBox>
);

const Slider = props => {
  const {
    size: { width },
  } = useContext(AppContext);

  if (!isBrowser()) {
    return <></>;
  }

  return (
    <Container>
      {width > desktopBaseWidth ? (
        <ListingSlider
          slides={props.children}
          deskItemWidth={props.deskItemWidth}
        />
      ) : (
        <CarouselSlider slides={props.children} />
      )}
    </Container>
  );
};

const SlideImage = Image;
const SlideTitle = Title;
const SlideText = Text;
const SlideText2 = Text2;
const SlideMore = More;
const SlideMoreAnchor = MoreAnchor;

export {
  Slide,
  SlideImage,
  SlideTitle,
  SlideText,
  SlideText2,
  SlideMore,
  SlideMoreAnchor,
};

export default Slider;
